<template>
  <div class="about">
    <section class="hero is-medium about-img">
      <div class="hero-body">
        <div class="container">
          <h1 class="about-title">{{ $t('navi.about') }}</h1>
        </div>
      </div>
    </section>

    <div class="company-info">
      <div class="container">
        <h5 class="header-title">{{ $t('about.companyHistory') }}</h5>
        <div class="columns">
          <div class="column">
            <div class="info-content">
              <h4 class="content-title">{{ $t('about.descriptionTitle1') }}</h4>
              <p class="content-txt">{{ $t('about.descriptionTxt1') }}</p>
              <p class="content-txt">{{ $t('about.descriptionTxt2') }}</p>
            </div>
          </div>
          <div class="column">
            <div class="info-content">
              <img class="info-img" src="@/images/img_aboutus_1.jpg"/>
            </div>
          </div>
        </div>
        <div class="columns reverse">
          <div class="column">
            <div class="info-content">
              <h4 class="content-title">{{ $t('about.descriptionTitle2') }}</h4>
              <p class="content-txt">{{ $t('about.descriptionTxt3') }}</p>
              <p class="content-txt">{{ $t('about.descriptionTxt4') }}</p>
              <p class="content-txt">{{ $t('about.descriptionTxt5') }}</p>
            </div>
          </div>
          <div class="column">
            <div class="info-content">
              <img class="info-img" src="@/images/img_aboutus_2.jpg"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <section class="hero is-medium country-img">
      <div class="hero-body">
        <div class="container">
          <h1>{{ $t('about.countries25') }}</h1>
        </div>
      </div>
      <div class="hero-foot">
        <div class="container">
          <p><span class="icon-spot"></span>{{ $t('about.cooperation') }}</p>
          <p><span class="icon-spot red"></span>{{ $t('about.location') }}</p>
        </div>
      </div>
    </section>

    <div class="location">
      <div class="container">
        <h5 class="header-title">{{ $t('about.location') }}</h5>
        <div class="columns">
          <div class="column">
            <h4 class="content-title">{{ $t('about.taiwan') }}</h4>
            <p class="content-txt" v-html="$t('about.address1')"></p>
            <h4 class="content-title">{{ $t('about.taiwan_en') }}</h4>
            <p class="content-txt" v-html="$t('about.address1_en')"></p>
          </div>
          <div class="column">
            <h4 class="content-title">{{ $t('about.china') }}</h4>
            <p class="content-txt" v-html="$t('about.address2')"></p>
            <h4 class="content-title">{{ $t('about.china_en') }}</h4>
            <p class="content-txt" v-html="$t('about.address2_en')"></p>
          </div>
        </div>
      </div>
    </div>
    <hr/>
    <div class="joinUs">
      <div class="container">
        <h5 class="header-title">{{ $t('about.recruitmentInfo') }}</h5>
        <div class="columns">
          <div class="column">
            <h4 class="content-title">{{ $t('about.joinUs') }}</h4>
            <p class="content-txt">{{ $t('about.joinUsTxt') }}</p>
            <a href="https://www.104.com.tw/company/1a2x6bkbqi" target="_blank"><div class="btn-see-more">{{ $t('about.joinUs') }}</div></a>
          </div>
          <div class="column">
            <img src="@/images/img_aboutus_joinus.jpg" alt="">
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'About',
}
</script>
<style lang="sass" scoped>
.about
  max-width: 100%
  .about-img
    min-height: 470px
    background-image: url('../images/img_aboutus_main.jpg')
    background-position: center center
    background-repeat: no-repeat
    background-size: cover
  .hero-body
    .container
      .about-title
        color: #ffffff
  .company-info,
  .location,
  .joinUs
    margin: 80px auto 80px
  .company-info
    .reverse
      display: flex
      flex-direction: row-reverse
      margin-top: 100px
      .column:first-child
        display: flex
        justify-content: flex-end
    .info-img
      width: 100%
  .country-img
    min-height: 500px
    background-image: url('../images/img_aboutus_25country.jpg')
    background-position: center center
    background-repeat: no-repeat
    background-size: cover
    .hero-body
      display: flex
      align-items: center
    .hero-foot
      padding-bottom: 30px
      p
        display: flex
        align-items: center
      .icon-spot
        width: 6px
        height: 6px
        padding: 3px
        margin: 0 5px
        border: solid 1px #ffffff
        border-radius: 50%
        background-color: #66b3ff
      .red
        background-color: #ff6347
  .location
    .content-txt
      margin-bottom: 30px
  .btn-see-more
    display: inline-block
    padding: 10px 13px
    margin-top: 8px
    color: #ffffff
    background-color: #005cb9
@media (max-width: 1023px)
  .about
    .company-info
      .columns
        display: block
        .column
          display: block
      .reverse
        margin-top: 80px
      .info-img
        width: 100%
@media (max-width: 768px)
  .about
    .columns
      margin: 0
    .about-img
      .hero-body
        display: flex
        align-items: center
    .company-info,
    .location,
    .joinUs
      margin: 50px auto 80px
    .company-info,
    .joinUs
      padding: 0
      overflow: hidden
      .container
        padding: 0
      .column:nth-child(1)
        padding: 2rem
      .column:nth-child(2)
        padding: 0
      .header-title
        padding: 0 2rem
    .company-info
      .reverse
        margin-top: 45px
    .country-img
      background-position: -900px center
      .hero-body
        align-items: flex-end
</style>
